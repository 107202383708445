<template>
  <div class='py-2 lg:py-8 px-4 lg:px-0'>
    <h1 class='text-3xl lg:text-4xl font-bold w-full lg:w-1/2'>Presentation Guideline</h1>
    <div class='mt-12'>
      <h2 class='text-xl lg:text-2xl font-bold w-full lg:w-1/2'>Notice for presentation</h2>
      <div class='mt-8'>
        <ul class='px-4 list-disc list-outside whitespace-pre-line text-gray-600 text-sm lg:text-base leading-6'>
          <li class='list-outside mt-2 mb-8'>
            제66차 국제학술대회는 온,오프라인 병행 개최되므로 원활한 생중계 송출을 위하여 발표시간 엄수에 대한 각별한 주의가 필요합니다.<br>오프라인 발표자에게는 발표시간 종료 1분 전에 팝업 안내가 되고 시간이 초과되면 자동으로 발표 슬라이드 마지막 페이지로 화면이 이동하오니 주어진 시간내 발표할 수 있도록 준비하시기 바랍니다.<br>
            또한 온라인 동영상 발표자는 정해진 시간에 맞춰 녹화해 주시기 바랍니다.
          </li>
          <li class='list-outside mt-2 mb-8'>
            At the 66<sup>th</sup> Annual Congress of the Korean Orthopaedic Association, we ask for your heightened attention in keeping your presentation time strictly within limits so as to run the conference smoothly.<br>
            Offline presenters will be notified by pop-up screen which will warn you 1 minute before the ending time, and the screen will automatically display the last page of your PPT slides when the time is up.<br>
            Please take this into consideration when preparing your presentation to present within the given time.<br>
            For online presenters using pre-recorded video file, please record at a given time. Thank you.
          </li>
        </ul>
      </div>
    </div>
    <div class='mt-8 lg:mt-24'>
      <div class='flex flex-col lg:flex-row justify-between items-start gap-y-2'>
        <h2 class='text-xl lg:text-2xl font-bold w-full lg:w-1/2'>Oral Presentation Guide / Free Papers</h2>
        <div class='w-full lg:w-1/2 pl-0 lg:pl-6'>
          <div class='flex flex-row justify-end gap-x-2 text-center'>
            <a href='https://d3r2ol85dktaw0.cloudfront.net/conferences/koa2022/presentation-guideline/koa2022_presentation_template.zip'
              target='_blank'
              :style='`background-color: ${eventMainThemeColor};`'
              class='text-xs lg:text-sm text-white font-semibold px-4 py-2 w-1/2 lg:w-72 flex flex-row justify-center items-center'>
              Presentation Template Download
            </a>
          </div>
        </div>
      </div>
      <div class='mt-8'>
        <ul class='px-4 list-disc list-outside whitespace-pre-line text-gray-600 text-sm lg:text-base leading-6'>
          <li class='list-outside mt-2 mb-8'>
            Extended abstract and completed thesis upload deadline: <span class='font-semibold' :style='`color: ${eventMainThemeColor};`'>August 12 (Fri), 2022</span>
          </li>
          <li class='list-outside mt-2 mb-8'>
            PPT files upload deadline: <span class='font-semibold' :style='`color: ${eventMainThemeColor};`'>September 30 (Fri), 2022</span><br>
            (If necessary, you may re-upload the revised file at the Preview Room of the congress venue at least one hour before your presentation time.)<br>
          </li>
          <li class='list-outside mt-2 mb-8'>
            Please adjust the monitor aspect ratio 16:9 of your presentation slides.
          </li>
        </ul>
      </div>
    </div>
    <div class='mt-8 lg:mt-24'>
      <div class='flex flex-col lg:flex-row justify-between items-start gap-y-2'>
      <h2 class='text-xl lg:text-2xl font-bold w-full lg:w-1/2'>Video Lecture (or Presentation) Guide / Guest Speakers or Overseas Presenter</h2>
      <div class='w-full lg:w-1/2 pl-0 lg:pl-6'>
        <div class='flex flex-row justify-end gap-x-2 text-center'>
          <a href='https://d3r2ol85dktaw0.cloudfront.net/conferences/koa2022/presentation-guideline/koa2022_zoom_guideline.pdf'
            target='_blank'
            :style='`background-color: ${eventMainThemeColor};`'
            class='text-xs lg:text-sm text-white font-semibold px-4 py-2 w-1/2 lg:w-72 flex flex-row justify-center items-center'>
            Zoom Guidelines Download
          </a>
          <a href='https://www.youtube.com/watch?v=KCi7z-bJJXw'
            target='_blank'
            :style='`background-color: ${eventMainThemeColor};`'
            class='text-xs lg:text-sm text-white font-semibold px-4 py-2 w-1/2 lg:w-72 flex flex-row justify-center items-center'>
            How to Record Using Microsoft PPT
          </a>
        </div>
      </div>
      </div>
      <div class='mt-8'>
        <ul class='px-4 list-disc list-outside whitespace-pre-line text-gray-600 text-sm lg:text-base leading-6'>
          <li class='list-outside mt-2 mb-8'>
            Thank you for accepting your online video lecture(or Presentation) at the 66<sup>th</sup> Annual Congress of the Korean Orthopaedic Association.
          </li>
          <li class='list-outside mt-2 mb-8'>
            The pre-recorded video files you provide will be streamed live at the time according to the 66<sup>th</sup> Annual Congress Program.
          </li>
          <li class='list-outside mt-2 mb-8'>
            Please download the guidelines or access the link above: "How to use Zoom program" (download pdf) or "How to use Microsoft PPT" (click on the link) for preparing your video lecture file.
          </li>
        </ul>
      </div>
    </div>
    <div class='mt-8 lg:mt-24'>
      <h2 class='text-xl lg:text-2xl font-bold w-full lg:w-1/2'>Poster Presentation Guide / E-Poster Session</h2>
      <div class='mt-8'>
        <ul class='px-4 list-disc list-outside whitespace-pre-line text-gray-600 text-sm lg:text-base leading-6'>
          <li class='list-outside mt-2 mb-8'>
            Due to COVID-19 situation, the KOA switched to e-poster so we can still present, participate, and share updated knowledge.<br>
            The e-posters will be presented on the website from <span class='font-semibold' :style='`color: ${eventMainThemeColor};`'>October 13 (Thu) to October 15 (Sat), 2022.</span><br>
            Please be sure to upload in <span class='font-semibold' :style='`color: ${eventMainThemeColor};`'>PDF (16:9 screen ratio)</span> files on the official website of the 66<sup>th</sup> Annual Congress of the Korean Orthopaedic Association.
          </li>
          <li class='list-outside mt-2 mb-8'>
            E-poster upload deadline: <span class='font-semibold' :style='`color: ${eventMainThemeColor};`'>September 30 (Fri), 2022</span>
          </li>
        </ul>
      </div>
    </div>
    <div class='mt-8 lg:mt-24'>
      <h2 class='text-xl lg:text-2xl font-bold w-full lg:w-1/2'>Video Presentation Guide / Virtual Video Session</h2>
      <div class='mt-8'>
        <ul class='px-4 list-disc list-outside whitespace-pre-line text-gray-600 text-sm lg:text-base leading-6'>
          <li class='list-outside mt-2 mb-8'>
            Presenters accepted for the video category should send a video file (mp4).
          </li>
          <li class='list-outside mt-2 mb-8'>
            Video upload deadline: <span class='font-semibold' :style='`color: ${eventMainThemeColor};`'>September 30 (Fri), 2022</span>
          </li>
        </ul>
      </div>
    </div>
    <div class='mt-8 lg:mt-24'>
      <h2 class='text-xl lg:text-2xl font-bold w-full lg:w-1/2'>How to upload</h2>
      <div class='mt-8'>
        <ul class='px-4 list-decimal list-outside whitespace-pre-line text-gray-600 text-sm lg:text-base leading-6'>
          <li class='list-outside mt-2 mb-8'>
            Visit the website of the 66th Annual Congress of the Korean Orthopaedic Association.
          </li>
          <li class='list-outside mt-2 mb-8'>
            Login with the ID that you used when submitting your abstract
          </li>
          <li class='list-outside mt-2 mb-8'>
            Select "Upload abstract Files" underneath the Call For Abstracts menu
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'PresentationGuideline',
  computed: {
    ...mapGetters('events', [
      'eventMainThemeColor',
    ]),
  }
}
</script>
